import { defineStore } from 'pinia'
import { ref } from 'vue'
import { appAxios } from '@/utils/appAxios'
const API_PREFIX = process.env.VUE_APP_API_PREFIX

export const useCaseStore = defineStore('CaseStore', () => {
    const conveyancingCases = ref([])
    const loadings = ref({
        list: false,
        store: false
    })
    const cases = ref()
    const history = ref(null)
    const editPermission = ref(null)

    function getCaseById(id) {
        loadings.value.get = true
        return new Promise((resolve, reject) => {
            appAxios.get(`${API_PREFIX}/cases/${id}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    async function getConveyancingCases(params) {
        loadings.value.list = true
        await appAxios.get(`${API_PREFIX}/cases`, params).then((response) => {
            conveyancingCases.value = response
        })
        loadings.value.list = false
    }

    const searchCase = (search) => {
        getConveyancingCases({
            search,
        })
    }

    const storeHistory = async (caseID, data) => {
        loadings.value.store = true
        await appAxios.post(`${API_PREFIX}/cases/${caseID}/histories`, data).then(() => {
            loadings.value.store = false
        }).catch(() => {
            loadings.value.store = false
        })
    }

    const editHistory = async (caseID, uniqID, data) => {
        loadings.value.store = true
        await appAxios.put(`${API_PREFIX}/cases/${caseID}/histories/${uniqID}`, data).then(() => {
            loadings.value.store = false
        }).catch(() => {
            loadings.value.store = false
        })
    }

    const getHistoryDetail = async (id) => {
        return new Promise((resolve, reject) => {
            appAxios.get(`${API_PREFIX}/cases/histories/outlook/${id}`).then((response) => {
                history.value = response.history
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    const getCorrespondent = (caseID, from) => {
        return new Promise((resolve, reject) => {
            appAxios.get(`${API_PREFIX}/correspondent`, { case_id: caseID, email: from }).then((response) => {
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }
    const getPhrases = (correspondent) => {
        return new Promise((resolve, reject) => {
            appAxios.get(`phrases`, { correspondent: correspondent, can_be_select_as_attachment: 1, type: 'Email' }).then((response) => {
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }
    const setLoadings = async (statu) => {
        loadings.value.store = statu
    }

    return {
        loadings,
        cases,
        conveyancingCases,
        history,
        getConveyancingCases,
        getHistoryDetail,
        searchCase,
        storeHistory,
        editHistory,
        getCorrespondent,
        getPhrases,
        setLoadings,
        editPermission,
        getCaseById
    }
})
