import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useRouter } from 'vue-router';
import { showNotification } from "@/utils/notification";

export const initialPropertyData = {
  data_type: 'auto',
  address_type: 'auto',
  line: '',
  postal_code: '',
  building_name: '',
  building_number: '',
  flat_number: '',
  street_name: '',
  city: '',
  county: '',
  address_block: '',
  description: '',
  latitude: '',
  longitude: '',
  uprn_number: '',
  ward: '',
  json_data: ''
}

export const useMemorandumStore = defineStore('MemorandumStore', () => {
  const memorandumValidate = ref(false)
  const router = useRouter();
  const mailBoxItem = window.Office.context.mailbox.item;
  const correspondentTypes = ref([])
  const phraseTypes = ref([])
  const incomingPhraseTypes = ref([
    { name: 'General Correspondence' },
    { name: 'Memorandum of Sale' }
  ])
  const attachmentTypes = ref([])
  const loading = ref(false)
  const loadingForm = ref(false)
  const formData = ref({
    case_ref: null,
    correspondent: null,
    phrase: null,
    phrase_id: null,
    attachments: [...mailBoxItem.attachments]
  })
  const memorandumForm = ref({
    new_build: null,
    tenure: null,
    sale_price: null,
    act_both_ends: 'No',
    act_both_case: null,
    seller: [],
    buyer: [],
    solicitor: {
      name: '',
      phone_number: '',
      email: '',
      company_id: '',
      address: {
        data_type: 'auto',
        line: '',
        postal_code: '',
        building_name: '',
        building_number: '',
        flat_number: '',
        street_name: '',
        city: '',
        county: '',
        address_block: '',
        description: '',
        latitude: '',
        longitude: '',
        uprn_number: '',
        ward: '',
        json_data: '',
        test: ''
      }
    },
    property: {
      data_type: 'auto',
      address_type: 'auto',
      line: '',
      postal_code: '',
      building_name: '',
      building_number: '',
      flat_number: '',
      street_name: '',
      city: '',
      county: '',
      address_block: '',
      description: '',
      latitude: '',
      longitude: '',
      uprn_number: '',
      ward: '',
      json_data: ''
    }
  })

  async function handleSubmitMemorandum() {
    const memorandumDetail = Object.assign({}, memorandumForm.value)
    if (Object.assign(formData.value, memorandumDetail)) {
      memorandumValidate.value = true
      showNotification('Memorandum added successfully', 'success')
      router.push('/')
    }
  }

  async function handleNewClient(client) {
    if (client.client_type === 'Seller') {
      memorandumForm.value.seller.push(client)
      showNotification('Seller added successfully', 'success')
    } else if (client.client_type === 'Buyer') {
      memorandumForm.value.buyer.push(client)
      showNotification('Buyer added successfully', 'success')
    }
    await router.push({ path: '/memorandum-details' })
  }

  async function handleUpdateClient(client, index) {
    if (client.client_type === 'Seller') {
      Object.assign(memorandumForm.value.seller[index], client)
    } else if (client.client_type === 'Buyer') {
      Object.assign(memorandumForm.value.buyer[index], client)
    }
    showNotification('Updated successfully', 'success')
    await router.push({ path: '/memorandum-details' })
  }

  async function handleDeleteClient(client) {
    if (client.client_type === 'Seller') {
      memorandumForm.value.seller.splice(client.id, 1)
    } else if (client.client_type === 'Buyer') {
      memorandumForm.value.buyer.splice(client.id, 1)
    }
    showNotification('Deleted successfully', 'success')
  }

  async function getClientByIndex(index, client_type) {
    if (client_type !== undefined && (memorandumForm.value.seller[index] || memorandumForm.value.buyer[index])) {
      return await client_type === 'Seller' ? memorandumForm.value.seller[index] : memorandumForm.value.buyer[index]
    } else {
      router.push({ path: "/memorandum-details" })
      showNotification("Client not found", "error")
    }
  }

  return {
    handleDeleteClient,
    handleUpdateClient,
    getClientByIndex,
    memorandumValidate,
    formData,
    memorandumForm,
    correspondentTypes,
    phraseTypes,
    attachmentTypes,
    loading,
    loadingForm,
    handleNewClient,
    handleSubmitMemorandum,
    incomingPhraseTypes
  }
})
